'use client'

import propTypes from 'prop-types'
import { useEffect, useState } from 'react'

interface Props {
    noSpace?: boolean
    rounded?: boolean
    children: any
    classList: any
    contentId: any
    headingId: any
    isVisible: boolean
    updateVisible: any
    modalSize: any
    showIcon?: any
    onHandleClose: any
    hideClose?: any
    modalBg?: any
    pageType?: any
    flush?: any
}
export default function Modal({
    noSpace,
    rounded,
    children,
    classList,
    contentId,
    headingId,
    isVisible,
    updateVisible,
    modalSize,
    showIcon,
    onHandleClose,
    hideClose,
    modalBg,
    flush,
}: Props) {
    const [modalVisible, setVisible] = useState(false)

    const onClose = () => {
        const toggleModal = !modalVisible
        setVisible(toggleModal)
        updateVisible(toggleModal)
        document.body.style.overflow = 'unset'
        if (onHandleClose) {
            return onHandleClose()
        }
    }

    useEffect(() => {
        setVisible(isVisible)

        if (isVisible) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isVisible])

    return (
        <>
            {modalVisible && (
                <div
                    id="defaultModal"
                    tabIndex={-1}
                    aria-hidden="true"
                    className={isVisible ? "fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full" : 'hidden'}
                >
                    <div className="relative w-full h-full max-w-2xl md:h-auto">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

Modal.propTypes = {
    children: propTypes.any,
    classList: propTypes.any,
    closeIconOnly: propTypes.bool,
    closeIconColour: propTypes.string,
    closeIconSize: propTypes.string,
    contentId: propTypes.any,
    headingId: propTypes.any,
    isVisible: propTypes.bool,
    updateVisible: propTypes.any,
    onHandleClose: propTypes.any,
    modalSize: propTypes.oneOf([
        'small',
        'medium',
        'large',
        'xlarge',
        'full',
        'wide',
        'custom',
        'sign-in',
        'prompt',
    ]),
    overlayBg: propTypes.string,
}
