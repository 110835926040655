import { useRecoilState } from 'recoil'
import { maintenanceState } from '@atoms/index'

import Alert from '../alert'

export default function Maintenance() {
    const [maintenanceEnabled] = useRecoilState(maintenanceState)

    if (!maintenanceEnabled) {
        return <></>
    }

    const data = {
        type: 'warning',
        content:
            'We are currently performing scheduled maintenance and will be back shortly.',
    }
    return <Alert data={data} />
}
