'use client'

import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { siteState } from '@atoms/index'
import Loader from '@components/loader'
import { isServer } from '@lib/services/serverService'
import { API_INIT } from '@lib/api-routes'

const SiteProvider = ({ children }) => {
    const [siteData, setSiteData] = useRecoilState(siteState)
    const [loading, setLoading] = useState<boolean>(true)
    // const [faviconSrc, setFaviconSrc] = useState('/favicon.svg')

    function removeWWW(url: string) {
        return url.replace(/^www\./, '')
    }

    const hostname = !isServer ? removeWWW(window.location.hostname) : ''

    // useEffect(() => {
    //     if (typeof window !== 'undefined') {
    //         window.onfocus = function () {
    //             setFaviconSrc('/favicon.svg')
    //         }

    //         window.onblur = function () {
    //             setFaviconSrc('/favicon-broken.svg')
    //         }
    //     }
    // }, [])

    const getSiteData = async () => {
        try {
            const token = localStorage.getItem('token')
            const response = await fetch(`${process.env.API_URL}${API_INIT}`, {
                method: 'POST',
                ...(hostname && {
                    body: JSON.stringify({
                        hostname: hostname,
                    }),
                }),
            })
            if (response.status === 200) {
                const json = await response.json()
                setSiteData(json)
                if (!token) {
                    localStorage.setItem('token', json.token)
                    localStorage.setItem('tokenType', 'site')
                }
            }
        } catch (error) {
            console.error('Failed to fetch site data:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!siteData) {
            getSiteData()
        } else {
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <Loader />
    }

    if (!siteData) {
        return (
            <section className="bg-white dark:bg-gray-900 h-screen">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-blue-600 dark:text-blue-500">
                            404
                        </h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                            Site not found
                        </p>
                        <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
                            Sorry, we can&apos;t find that site on our network.
                            If you are the site owner, please get in touch.
                        </p>
                        <a
                            href="https://datingkit.io"
                            className="inline-flex text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-blue-900 my-4"
                        >
                            Dating Kit Homepage
                        </a>
                    </div>
                </div>
            </section>
        )
    }

    return <>{children}</>
}

export default SiteProvider
