'use client'

import { atom } from 'recoil'
import { isServer } from '@lib/services/serverService'
import { SiteDataType } from '@lib/types/siteData.types'

export const notificationState = atom({
    key: 'notifications',
    default: null,
})

export const mobileNavigation = atom({
    key: 'mobileNavigation',
    default: false,
})

export const maintenanceState = atom({
    key: 'maintenance',
    default: false,
})

// @ts-ignore
export const userState = atom({
    key: 'user',
    default: null,
})

export const siteState = atom<SiteDataType | null>({
    key: 'siteState',
    default: null,
})

export const messagesState = atom({
    key: 'messages',
    default: null,
})

export const membersState = atom({
    key: 'members',
    default: [],
})

export const currentModalState = atom({
    key: 'currentModalState',
    default: 'CLOSED',
})

export const currentModalDataState = atom({
    key: 'currentModalDataState',
    default: {},
})
