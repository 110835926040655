'use client'

import { currentModalState, currentModalDataState } from '@atoms/index'
import { useRecoilState } from 'recoil'

import Modal from '@components/modal'
import Loader from '@components/loader'

const ModalHandler = () => {
    const [currentModal, setCurrentModal] =
        useRecoilState<any | false>(currentModalState)
    const [currentModalData, setCurrentModalData] = useRecoilState<any | false>(
        currentModalDataState
    )

    const onToggleModal = () => {
        setCurrentModal('CLOSED')
        setCurrentModalData({})
    }

    const modals = [
        {
            name: 'LOADER_MODAL',
            modalSize: 'large',
            contentId: '',
            headingId: '',
            flush: true,
            noSpace: true,
            render: <Loader />,
        }
    ]

    const modalData = modals.filter((modal) => modal.name === currentModal)[0]

    return (
        <Modal
            noSpace={modalData?.noSpace}
            rounded={true}
            modalSize={modalData?.modalSize}
            contentId={modalData?.contentId}
            headingId={modalData?.headingId}
            isVisible={currentModal !== 'CLOSED'}
            updateVisible={onToggleModal}
            flush={modalData?.flush}
            modalBg="gray"
        >
            {modalData?.render}
        </Modal>
    )
}

export default ModalHandler
