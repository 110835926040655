import Link from 'next/link'
import { useRecoilState } from 'recoil'
import { siteState } from '@atoms/index'

export default function SiteLogo(props) {
    const [siteData, setSiteData] = useRecoilState(siteState)
    const { type } = props
    return (
        <Link href="/">
            <div className="flex items-center cursor-pointer">
                <svg
                    className="block h-6 w-6 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1024 1024"
                    stroke="currentColor"
                    fill={siteData.design.theme}
                >
                    <path
                        d="M433,45c50,134,24,207-32,265c-61,64-156,112-223,206C89,641,74,916,395,988c-135-71-164-277-18-406
          c-38,125,32,205,119,176c85-29,141,32,139,102c-1,48-20,89-69,112c209-37,293-210,293-342c0-174-155-198-77-344
          c-93,8-125,69-116,169c6,66-63,111-114,81c-41-25-40-73-4-109C625,351,655,176,433,45z"
                    />
                </svg>
                <span
                    className={`font-semibold text-xl tracking-tight ${
                        type === 'dark' ? 'text-gray-900' : 'text-gray-100'
                    }`}
                >
                    {siteData?.seo?.title
                        ? siteData?.seo?.title
                        : 'Online Dating'}
                </span>
            </div>
        </Link>
    )
}
