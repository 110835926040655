'use client'

import { useState, useRef } from 'react'
import { useRouter } from 'next/navigation'
import Link from 'next/link'
import { useRecoilState } from 'recoil'
import { userState } from '@atoms/index'

import { useClickOutside } from '../../lib/hooks/useClickOutside.js'
import { getPrimaryMedia } from '@lib/services/mediaService'

export default function UserMenu() {
    const [userData] = useRecoilState<any>(userState)
    const [showDropdown, setShowDropdown] = useState(false)
    const clickRef = useRef<any>()

    const onClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(clickRef, onClickOutside)

    const router = useRouter()

    const navigation = [
        {
            name: 'Edit profile',
            href: '/edit',
        },
        {
            name: 'View your profile',
            href: `/member/${userData.username}`,
        },
        {
            name: 'Support',
            href: `/support`,
        },
    ]

    const logout = () => {
        router.push('/logout')
    }

    const UserImage = () => {
        const primaryImage = getPrimaryMedia(userData.media)
        return (
            <img
                className="h-8 w-8 rounded-full"
                src={primaryImage?.url}
                alt="Primary image"
            />
        )
    }

    return (
        <div className="ml-3 relative mr-2" ref={clickRef}>
            <div>
                <button
                    className="flex h-8 w-8 text-sm focus:outline-none focus:border-white transition duration-150 ease-in-out bg-gray-100 rounded-full"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    {UserImage()}
                </button>
            </div>
            {showDropdown && (
                <div className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                    <div
                        className="py-1 rounded-md bg-white shadow-xs"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                    >
                        {navigation.map((link, i) => {
                            return (
                                <Link
                                    href={link.href}
                                    role="menuitem"
                                    onClick={() =>
                                        setShowDropdown(!showDropdown)
                                    }
                                    key={i}
                                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                >
                                    {link.name}
                                </Link>
                            )
                        })}

                        {userData?.pro !== 'true' && (
                            <Link
                                href="/upgrade"
                                role="menuitem"
                                onClick={() => setShowDropdown(!showDropdown)}
                                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            >
                                Upgrade
                            </Link>
                        )}

                        <div role="menuitem" onClick={logout}>
                            <a className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
                                Sign Out
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
