'use client'

import React from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '@atoms/index'

import SiteLogo from '../site-logo'
import Navigation from '../navigation'
import UserMenu from '../user-menu'
import Notification from '../notification'
import Maintenance from '../maintenance'
import CreditButton from '@components/credit-button'

function Header() {
    const [userData] = useRecoilState<any>(userState)

    if (userData === null) {
        return <></>
    }

    return (
        <header>
            <Maintenance />
            <nav className="bg-gray-900">
                <div className="relative max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center flex-row-reverse md:flex-row">
                            <SiteLogo />
                            {userData.id && <Navigation />}
                        </div>
                        <div className="flex items-center">
                            {/* {userData.id && <Notification />} */}
                            <div className="hidden md:block">
                                {userData.id && <CreditButton />}
                            </div>
                            {userData.id && <UserMenu />}
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
