'use client'

import { siteState } from '@lib/atoms'
import { SiteDataType } from '@lib/types/siteData.types'
import { GoogleAnalytics } from '@next/third-parties/google'
import { useRecoilState } from 'recoil'

const Analytics = () => {
    const [siteData] = useRecoilState<SiteDataType | null | any>(siteState)
    return siteData.seo.gTag && <GoogleAnalytics gaId={siteData.seo.gTag} />
}

export default Analytics
