export const getPrimaryMedia = (media) => {
    if (!media) {
        return false
    }
    if (media?.error) {
        return false
    }
    const primaryIndex = media?.findIndex((item) => item.is_primary === 1)
    return media[primaryIndex]
}
