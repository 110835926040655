import { useRecoilState } from 'recoil'
import { siteState, userState } from '@atoms/index'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { SiteDataType } from '@lib/types/siteData.types'
import { useClickOutside } from '@lib/hooks/useClickOutside'

const CreditButton = () => {
    const [userData] = useRecoilState<any>(userState)
    const [siteData] = useRecoilState<SiteDataType | null | any>(siteState)
    const clickRef = useRef<any>()

    const onClickOutside = () => {
        setShowDropdown(false)
    }
    useClickOutside(clickRef, onClickOutside)

    const [showDropdown, setShowDropdown] = useState(false)

    const totalAvailableCredits =
        (Number(userData.credits) || 0) + (Number(userData.bonus) || 0)
    const userHasCredits = !userData.pro && totalAvailableCredits > 0
    const userNoCredits = !userData.pro && totalAvailableCredits === 0

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="flex items-center border border-yellow-500 px-2 text-white text-sm rounded-md h-8 group"
            >
                {userData.pro && 'Pro member'}
                {userHasCredits && `Credits: ${totalAvailableCredits}`}
                {userNoCredits && 'Purchase credits'}
                {Number.isNaN(totalAvailableCredits) &&
                    'Loading credits failed'}
            </button>

            {showDropdown && (
                <div
                    className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                    ref={clickRef}
                >
                    <div className="relative after:absolute after:-top-1.5 after:right-8 after:-translate-x-1/2 after:h-3 after:w-3 after:rounded-tl-sm after:rotate-45 after:bg-white"></div>
                    <div
                        className="py-1 rounded-md bg-white shadow-xs"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                    >
                        <span className="block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out">
                            Purchased: {userData.credits ? userData.credits : 0}
                        </span>
                        <span className="block px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out">
                            Bonus: {userData.bonus ? userData.bonus : 0}
                        </span>
                        <Link
                            onClick={() => setShowDropdown(false)}
                            role="menuitem"
                            className={`block px-4 py-2 text-sm leading-5 text-gray-700 bg-${siteData?.design.theme}-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out font-bold`}
                            href="/upgrade"
                        >
                            Upgrade
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CreditButton
