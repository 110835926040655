'use client'

import { siteState, userState } from '@lib/atoms'
import { useRecoilState } from 'recoil'
import Link from 'next/link'
import { SiteDataType } from '@lib/types/siteData.types'

const Footer = () => {
    const [siteData] = useRecoilState<SiteDataType | null>(siteState)
    const [userData] = useRecoilState<any>(userState)

    if (userData === null) {
        return <></>
    }

    return (
        <footer className="max-w-screen-lg mx-auto border-none px-4">
            <section className="flex flex-col md:flex-row md:justify-between :border-solid border-t text-gray-700 font-light text-sm pt-4 pb-6 md:pt-5 md:pb-6 w-full">
                <div>
                    <p className="leading-8 tracking-wide">
                        Copyright &copy; {siteData?.meta?.registered} -{' '}
                        {new Date().getFullYear()} {siteData?.name}.
                    </p>
                </div>
                <div>
                    <Link
                        href="/terms"
                        className="leading-8 tracking-wide mr-2"
                    >
                        Privacy Policy
                    </Link>
                </div>
            </section>
        </footer>
    )
}

export default Footer
